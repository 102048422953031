<template>
  <div :class="data.type">
    <shared-index
      :type="data.type"
      :titles="data.index.titles"
      :columns="data.index.columns"
      :actions="data.index.actions"
      :header-actions="data.index.headerActions"
      :filterable="data.index.headerActions.includes('filter')"
    />
  </div>
</template>

<script>
import SharedIndex from '@/components/shared/index'

export default {
  name: 'SharedPageIndex',
  components: {
    SharedIndex
  },
  data() {
    return {
      data: this.$route.meta.data
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
